import React from "react";
import { Link } from "gatsby";
import ourWork1 from "../img/ourwork-custom-exhibit-designs.jpg";
import ourWork2 from "../img/ourwork-tradeshows.jpg";
import ourWork3 from "../img/ourwork-corporate-events.jpg";
import ourWork4 from "../img/ourwork-conferences.jpg";
import ourWork5 from "../img/ourwork-private-event.jpg";

export const OurWork = ({ title, showMore }) => (
  <section className={`OurWork ${!showMore ? "OurWork--splatter" : ""}`}>
    <div className="columns is-multiline">
      {title ? (
        <div className="column is-12">
          <div className="has-text-centered">
            <h3 className="is-size-1 title-underline">Our Work</h3>
          </div>
        </div>
      ) : null}
      <div className="column is-6 has-text-centered">
        <div
          className="our-work"
          style={{
            background: `linear-gradient(rgba(0, 0, 0, 0.7),rgba(0, 0, 0, 0.7)), url(${ourWork1})`,
            backgroundSize: `cover`
            // backgroundPosition: `top left`,
            // backgroundAttachment: `fixed`
          }}
        >
          <div className="OurWork__headings">
            <h3 className="is-size-3 has-text-white has-subtitle">
              Custom Built exhibits
            </h3>
          </div>
          <Link
            className="btn btn-outline-white"
            to="/our-work/custom-exhibits"
          >
            See More
          </Link>
        </div>
      </div>
      <div className="column is-6 has-text-centered">
        <div
          className="our-work"
          style={{
            background: `linear-gradient(rgba(0, 0, 0, 0.7),rgba(0, 0, 0, 0.7)), url(${ourWork2})`,
            backgroundSize: `cover`
            // backgroundPosition: `top left`,
            // backgroundAttachment: `fixed`
          }}
        >
          <div className="OurWork__headings">
            <h3 className="is-size-3 has-text-white has-subtitle">
              Tradeshows
            </h3>
          </div>
          <Link className="btn btn-outline-white" to="/our-work/tradeshows">
            See More
          </Link>
        </div>
      </div>
      <div className="column is-4 has-text-centered">
        <div
          className="our-work"
          style={{
            background: `linear-gradient(rgba(0, 0, 0, 0.7),rgba(0, 0, 0, 0.7)), url(${ourWork3})`,
            backgroundSize: `cover`
            // backgroundPosition: `top left`,
            // backgroundAttachment: `fixed`
          }}
        >
          <div className="OurWork__headings">
            <h3 className="is-size-3 has-text-white has-subtitle">
              Corporate Events
            </h3>
          </div>
          <Link
            className="btn btn-outline-white"
            to="/our-work/corporate-events"
          >
            See More
          </Link>
        </div>
      </div>
      <div className="column is-4 has-text-centered">
        <div
          className="our-work"
          style={{
            background: `linear-gradient(rgba(0, 0, 0, 0.7),rgba(0, 0, 0, 0.7)), url(${ourWork4})`,
            backgroundSize: `cover`
            // backgroundPosition: `top left`,
            // backgroundAttachment: `fixed`
          }}
        >
          <div className="OurWork__headings">
            <h3 className="is-size-3 has-text-white has-subtitle">
              Conferences
            </h3>
          </div>
          <Link className="btn btn-outline-white" to="/our-work/conferences">
            See More
          </Link>
        </div>
      </div>
      <div className="column is-4 has-text-centered">
        <div
          className="our-work"
          style={{
            background: `linear-gradient(rgba(0, 0, 0, 0.7),rgba(0, 0, 0, 0.7)), url(${ourWork5})`,
            backgroundSize: `cover`
            // backgroundPosition: `top left`,
            // backgroundAttachment: `fixed`
          }}
        >
          <div className="OurWork__headings">
            <h3 className="is-size-3 has-text-white has-subtitle">
              Private Events
            </h3>
          </div>
          <Link className="btn btn-outline-white" to="/our-work/private-events">
            See More
          </Link>
        </div>
      </div>
    </div>
    {showMore ? (
      <div className="columns">
        <div className="column is-12 has-text-centered">
          <Link className="btn btn-outline" to="/our-work">
            See More
          </Link>
        </div>
      </div>
    ) : null}
  </section>
);

export default OurWork;
