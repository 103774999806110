import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";

import Layout from "../components/Layout";
import Features from "../components/Features";
import BlogRoll from "../components/BlogRoll";

import Facts from "../components/Facts";
import Clients from "../components/Clients";
import Services from "../components/Services";
import OurWork from "../components/OurWork";
import TestimonialsUnveil from "../components/TestimonialsUnveil";

import apartPaint from "../img/apart-paint.png";
import blogPaint from "../img/blog-paint.jpg";
import heroPaint from "../img/homehero-paint.png";
import clientsPaint from "../img/clientsPaint.png";

export const IndexPageTemplate = ({
  image,
  title,
  heading,
  subheading,
  mainpitch,
  description,
  intro,
  services
}) => (
  <div className="home">
    <div
      className="full-width-image margin-top-0 home-hero"
      style={{
        background: `linear-gradient(rgba(0, 0, 0, 0.57),rgba(0, 0, 0, 0.57)), url(${
          !!image.childImageSharp ? image.childImageSharp.fluid.src : image
        })`,
        // backgroundImage: `url(${
        //   !!image.childImageSharp ? image.childImageSharp.fluid.src : image
        // })`,
        // backgroundPosition: `top left`,
        backgroundSize: `cover`
      }}
    >
      <div
        style={{
          background: `url(${heroPaint}) no-repeat bottom right`,
          backgroundSize: `contain`,
          width: `800px`,
          height: `inherit`,
          position: `absolute`,
          right: "0",
          backgroundPositionX: "15rem"
          // backgroundAttachment: `fixed`
        }}
      />
      <div
        className="hero-home__copy"
        // style={{
        //   display: "flex",
        //   height: "150px",
        //   lineHeight: "1",
        //   justifyContent: "space-around",
        //   alignItems: "left",
        //   flexDirection: "column"
        // }}
      >
        <h1
          className="is-size-1 has-margin-bot-2"
          // style={{
          //   boxShadow:
          //     "rgb(255, 68, 0) 0.5rem 0px 0px, rgb(255, 68, 0) -0.5rem 0px 0px",
          //   backgroundColor: "rgb(255, 68, 0)",
          //   color: "white",
          //   lineHeight: "1",
          //   padding: "0.25em"
          // }}
        >
          {title}
        </h1>
        <p
          className="is-size-5-mobile is-size-5-tablet is-size-4-widescreen"
          // style={{
          //   boxShadow:
          //     "rgb(255, 68, 0) 0.5rem 0px 0px, rgb(255, 68, 0) -0.5rem 0px 0px",
          //   backgroundColor: "rgb(255, 68, 0)",
          //   color: "white",
          //   lineHeight: "1",
          //   padding: "0.25em"
          // }}
        >
          {subheading}
        </p>
        <Link className="btn" to="/our-work">
          Explore
        </Link>
      </div>
    </div>
    <section className="">
      <div className="container">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div className="">
              <section className="section">
                <div className="content">
                  <div className="has-text-centered">
                    <h1 className="is-size-1 title-underline">
                      {mainpitch.title}
                    </h1>
                  </div>
                  <div className="content">
                    <p className="is-size-5">{mainpitch.description}</p>
                  </div>
                </div>
                <div className="columns">
                  <div className="column is-12 has-text-centered">
                    <Link className="btn btn-outline" to="/needs-assessment/">
                      Take our Free Needs Assessment
                    </Link>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>

      <div
        id="home-bluepaint"
        style={{
          background: `url(${clientsPaint}) no-repeat`,
          backgroundSize: `initial`,
          backgroundPosition: `center 15%`
          // backgroundAttachment: `fixed`
        }}
      >
        <section className="section">
          <div className="container">
            <div className="columns is-multiline">
              <div className="column is-10 is-offset-1">
                <div className="has-text-centered">
                  <h3 className="is-size-2 title-underline">{intro.heading}</h3>
                  {/* <p>{description}</p> */}
                </div>
              </div>
              <div className="column is-12">
                <Features gridItems={intro.blurbs} />
              </div>
            </div>
          </div>
        </section>
        <Clients />
        <div className="container">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <OurWork showMore={true} />
            </div>
          </div>
        </div>
      </div>

      <div
        className="container"
        style={{
          background: `url(${apartPaint}) no-repeat bottom left`,
          backgroundSize: `contain`
          // backgroundPosition: `top left`,
          // backgroundAttachment: `fixed`
        }}
      >
        <div>
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <section
                id="apart"
                className="section"
                // style={{
                //   background: `url(${apartPaint}) no-repeat bottom left`,
                //   backgroundSize: `contain`
                //   // backgroundPosition: `top left`,
                //   // backgroundAttachment: `fixed`
                // }}
              >
                <div className="columns">
                  <div className="column is-12">
                    <div className="has-text-centered">
                      <h3 className="is-size-1 title-underline">
                        What Sets us Apart
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="columns">
                  <div className="column">{/* Video placeholder */}</div>
                  <div className="column">
                    <div className="content">
                      <p className="is-size-5">
                        Unveil is your key to expertise and professionalism in
                        the world of exhibition production services. The work
                        Unveil has provided over the years ranges from
                        customized exhibit productions to elegant, intimate
                        private event productions. You can feel confident
                        trusting Unveil with all of your comprehensive exhibit
                        production need and can expect a truly tailored
                        experience to meet all of your exhibition needs.
                      </p>
                      <Link className="btn btn-outline" to="/about">
                        See More
                      </Link>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
      <Facts />

      <div className="container">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <Services services={services} />
          </div>
        </div>
      </div>

      <TestimonialsUnveil />
      <div className="container">
        <div className="columns">
          <div className="column is-12">
            <section
              id="blog"
              style={{
                background: `url(${blogPaint}) no-repeat top center`,
                backgroundSize: `80%`
                // backgroundAttachment: `fixed`
              }}
            >
              <div className="columns">
                <div className="column is-12">
                  <div className="has-text-centered">
                    <h3 className="is-size-1 title-underline">Blog</h3>
                  </div>
                  <BlogRoll />
                  {/* <div className="column is-12 has-text-centered">
                    <Link className="btn btn-outline" to="/blog">
                      Read more
                    </Link>
                  </div> */}
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </section>
  </div>
);

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array
  })
};

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout isHome={true}>
      <IndexPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        mainpitch={frontmatter.mainpitch}
        description={frontmatter.description}
        intro={frontmatter.intro}
        services={frontmatter.services}
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object
    })
  })
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        subheading
        mainpitch {
          title
          description
        }
        intro {
          heading
          blurbs {
            image {
              childImageSharp {
                fluid(maxWidth: 240, quality: 64) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            text
          }
        }
        services {
          heading
          description
          service {
            image {
              childImageSharp {
                fluid(maxWidth: 240, quality: 64) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            text
          }
        }
      }
    }
  }
`;
