import React from "react";

import clientPaint from "../img/clientsPaint.png";

import cogito from "../img/clients/cogito-logo.png";
import heliolite from "../img/clients/heliolite-logo.png";
import huevocartoon from "../img/clients/logo_huevocartoon.png";
import novel from "../img/clients/novel-logo.png";
import frameDisplays from "../img/clients/frame-displays-logo.png";
import steveMadden from "../img/clients/stevemadden-logo.png";
import foco from "../img/clients/foco-logo.png";
import fujitsu from "../img/clients/fujitsu-logo.png";
import telos from "../img/clients/telos-logo.png";
import zoya from "../img/clients/zoya-logo.png";

export const Clients = () => (
  <section
    className="section has-background-grey"
    // style={{
    //   background: `url(${clientPaint}) no-repeat center`,
    //   backgroundSize: `contain`
    //   // backgroundAttachment: `fixed`
    // }}
  >
    <div className="container">
      <div className="columns">
        <div className="column is-10 is-offset-1">
          <h3 className="is-size-2 title-underline">Some of Our Clients</h3>
        </div>
      </div>

      <div className="columns is-vcentered">
        <div className="column has-text-centered">
          <img
            src={cogito}
            alt="Cogito Logo"
            // style={{ width: "2em", height: "2em" }}
          />
        </div>
        <div className="column has-text-centered">
          <img
            src={heliolite}
            alt="Heliolite Logo"
            // style={{ width: "2em", height: "2em" }}
          />
        </div>
        <div className="column has-text-centered">
          <img
            src={huevocartoon}
            alt="Huevocartoon Logo"
            // style={{ width: "2em", height: "2em" }}
          />
        </div>
        <div className="column has-text-centered">
          <img
            src={novel}
            alt="Novel Collection Logo"
            // style={{ width: "2em", height: "2em" }}
          />
        </div>
        <div className="column has-text-centered">
          <img
            src={frameDisplays}
            alt="Frame Displays Logo"
            // style={{ width: "2em", height: "2em" }}
          />
        </div>
        <div className="column has-text-centered">
          <img
            src={steveMadden}
            alt="Steve Madden Logo"
            // style={{ width: "2em", height: "2em" }}
          />
        </div>
        <div className="column has-text-centered">
          <img
            src={foco}
            alt="FOCO Logo"
            // style={{ width: "2em", height: "2em" }}
          />
        </div>
        <div className="column has-text-centered">
          <img
            src={fujitsu}
            alt="Fujitsu Logo"
            // style={{ width: "2em", height: "2em" }}
          />
        </div>
        <div className="column has-text-centered">
          <img
            src={telos}
            alt="Telos Logo"
            // style={{ width: "2em", height: "2em" }}
          />
        </div>
        <div className="column has-text-centered">
          <img
            src={zoya}
            alt="Zoya Logo"
            // style={{ width: "2em", height: "2em" }}
          />
        </div>
      </div>
    </div>
  </section>
);

export default Clients;
