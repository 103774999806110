import React from "react";
import { Link } from "gatsby";
import serviceIcon from "../img/service-icon.png";

export const Services = ({ services }) => (
  <section className="section">
    <div className="columns">
      <div className="column is-12">
        <div className="content">
          <div className="has-text-centered">
            <h3 className="is-size-1 has-text-centered title-underline">
              {services.heading}
            </h3>
          </div>
          <p className="is-size-5 has-margin-bot-3">{services.description}</p>
        </div>
      </div>
    </div>
    <div className="columns is-multiline">
      {services.service.map(service => (
        <div key={service.text} className="column is-3 has-text-centered">
          <img
            src={service.image.childImageSharp.fluid.src}
            style={{ width: "8em", height: "8em" }}
            alt={service.text + " icon"}
          />
          <h4 className="is-size-5">{service.text}</h4>
        </div>
      ))}
    </div>
    <div className="column is-12 has-text-centered">
      <Link className="btn btn-outline" to="/services">
        See More
      </Link>
    </div>
  </section>
);

export default Services;
