import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Link, graphql, StaticQuery } from "gatsby";

class BlogRoll extends React.Component {
  render() {
    const { data } = this.props;
    const { edges: posts } = data.allMarkdownRemark;

    return (
      <Fragment>
        <div className="columns is-multiline">
          {posts &&
            posts.map(({ node: post }) => (
              <Fragment>
                <div className="column is-4" key={post.id}>
                  <article className="content">
                    {/* <p>
                    <Link
                      className="title has-text-primary is-size-4"
                      to={post.fields.slug}
                    >
                      {post.frontmatter.title}
                    </Link>
                    <span> &bull; </span>
                  </p> */}
                    <div className="BlogRoll__img-container">
                      <span className="BlogRoll__date">
                        {post.frontmatter.date}
                      </span>
                      <img
                        src={post.frontmatter.image.childImageSharp.fluid.src}
                      />
                    </div>
                    <h4 className="is-size-3">
                      {post.frontmatter.title}
                      <br />
                      {post.frontmatter.subTitle}
                    </h4>
                    {/* <p className="is-size-5">{post.excerpt}</p> */}
                    <Link className="btn btn-outline" to={post.fields.slug}>
                      Read More
                    </Link>
                  </article>
                </div>
                {/* <div className="is-parent column is-6" key={post.id}>
                </div> */}
              </Fragment>
            ))}
          {/* <div className="column is-12 has-text-centered">
            <Link className="btn btn-outline" to="/blog">
              Blog
            </Link>
          </div> */}
        </div>
      </Fragment>
    );
  }
}

BlogRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array
    })
  })
};

export default () => (
  <StaticQuery
    query={graphql`
      query BlogRollQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 400)
              id
              fields {
                slug
              }
              frontmatter {
                title
                subTitle
                image {
                  childImageSharp {
                    fluid(maxWidth: 500, quality: 64) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
                templateKey
                date(formatString: "MMM, YYYY")
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <BlogRoll data={data} count={count} />}
  />
);
