import React from "react";

import factsbg from "../img/facts/facts-bg.jpg";
import puzzle from "../img/facts/puzzle.svg";
import wrench from "../img/facts/wrench.svg";
import happyClients from "../img/facts/happy-clients.svg";

export const Facts = () => (
  <section
    id="facts"
    className="section"
    style={{
      backgroundImage: `url(${factsbg})`,
      backgroundSize: `cover`
      // backgroundPosition: `top left`,
      // backgroundAttachment: `fixed`
    }}
  >
    <div className="container">
      <div className="columns">
        <div className="column has-text-centered">
          <img
            src={puzzle}
            alt="Custom icon"
            style={{ width: "6em", height: "6em" }}
          />
          <span className="facts-number has-text-white">943</span>
          <h4 className="is-size-5 has-text-white">custom exhibits</h4>
        </div>
        <div className="column has-text-centered">
          <img
            src={wrench}
            alt="Wrench icon"
            style={{ width: "6em", height: "6em" }}
          />
          <span className="facts-number has-text-white">378</span>
          <h4 className="is-size-5 has-text-white">labor installs</h4>
        </div>
        <div className="column has-text-centered">
          <img
            src={happyClients}
            alt="Happy Clients icon"
            style={{ width: "6em", height: "6em" }}
          />
          <span className="facts-number has-text-white">651</span>
          <h4 className="is-size-5 has-text-white">happy clients</h4>
        </div>
      </div>
    </div>
  </section>
);

export default Facts;
